import styled from "styled-components";
import React, { useState, useCallback, useEffect } from "react";
import PageHeader from "../components/pageHeader";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import languages from "../libs/languages";
// import { setLocale } from "react-i18nify";
import i18n from '../i18n';
import { t, Translate } from 'react-i18nify';
import "./style/index.css";
// import bghome from "../assets/images/bg-home.png";
// import logo from "../assets/images/logo.png";

import { setLocale, setTranslations } from 'react-i18nify';

// setLocale(defaultLang);

import {
  UserOutlined,
  SettingOutlined,
  PieChartOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import { Menu, Layout, Modal, Select } from "antd";

const { Header, Content, Footer } = Layout;

const Style = styled.div`
  .header {
    height: 64px;
    display: flex;
    align-items: center;
    width: "100%";
    border-bottom: 1px solid #eee;
  }
  .icon-pointer {
    cursor: pointer;
  }
`;

export default function Index({ notify, setLoading, logined, setLogined }) {
  const navigate = useNavigate();

  const language = navigator.language.toLowerCase();

  // const i18nOptions = Object.keys(i18n)
  const i18nOptions = Object.keys(languages).map(item => {
    return {
      label: item,
      value: item
    }
  })

  useEffect(() => {
    document.title = "Voilatech";
  }, []);

  const handleChange = (value: string) => {
    setTranslations(i18n);
    setLocale(value)
  };

  return (
    <Style>
      <div className="container">
        
        <div className="header pointer">
          <span style={{color: '#000', fontWeight: 500}}>
            <span className="svg-icon" style={{marginRight:'0.5rem'}}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="tabler-icon tabler-icon-gift"
              >
                <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z"></path>
                <path d="M12 8l0 13"></path>
                <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7"></path>
                <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5"></path>
              </svg>
            </span>
            <Translate value="home.header1" />
            
          </span>
          <span style={{color: '#fd7e14'}}>
            <a
              href="https://ai.voilatech.co.jp/login"
              style={{textDecoration:'none',color: '#fd7e14'}}
            >
              <span style={{margin: '0 0.2em 0 0.5em'}}><Translate value="home.get_start_btn_text" /></span>
            </a>
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="orange"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="tabler-icon tabler-icon-arrow-right"
              >
                <path d="M5 12l14 0"></path>
                <path d="M13 18l6 -6"></path>
                <path d="M13 6l6 6"></path>
              </svg>
            </span>
          </span>
        </div>
        <Header className="header">
          <PageHeader pathname="/" routename="Voilatech" />
        </Header>
        <div className="main-1">
            <div className="btn-center"><Translate value="home.low_cost_ai_text" /></div>
            <div className="h1"><Translate value="home.easy_to_use_text" /></div>
            <div className="h1 h1-FD7E14"><Translate value="home.transcription_ai_apis_text" /></div>
            <div
                style={{padding: '0 10px',boxSizing: 'border-box',marginTop: '0.86rem', fontSize: '1.14rem' , fontWeight: 400,textAlign: 'center'}}>
                <Translate value="home.transcription_ai_api_desc_text" />
            </div>
            <div className="start-btn pointer" size="large" style={{marginTop: '1.43rem'}}>
                <a href="https://ai.voilatech.co.jp/login" style={{textDecoration:'none',color: 'black'}}><Translate value="home.get_start_btn_text" /> ></a>
            </div>
            <div className="tags">
                <span className="tag pointer" style={{color: '#15aabf',backgroundColor: '#E7F6F8'}}>
                    <span className="icon-svg svg-rotate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            transform="rotate(-45)translate(0,3)">
                            <path d="M4 8l4 -4"></path>
                            <path d="M14 4l-10 10"></path>
                            <path d="M4 20l16 -16"></path>
                            <path d="M20 10l-10 10"></path>
                            <path d="M20 16l-4 4"></path>
                        </svg>
                    </span>
                    <span className="tag-text"><Translate value="home.speech_to_text_api_text" /></span>
                </span>
                <span className="tag pointer" style={{color: '#40c057',backgroundColor: '#ECF9EE'}}>
                    <span className="icon-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            transform="translate(0,3)">
                            <path d="M8 9h8"></path>
                            <path d="M8 13h6"></path>
                            <path
                                d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z">
                            </path>
                        </svg>
                    </span>
                    <span className="tag-text"><Translate value="home.background_music_separation_api_text" /></span>
                </span>
                
            </div>
            <span className="bg-box">
                <span className="bg-img" style={{backgroundImage: 'url(../assets/images/bg-home.png)'}}></span>
            </span>
        </div>
        <div className="line"></div>
        <div className="main-2">
            <div>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        transform="rotate(-45)translate(0,3)">
                        <path d="M4 8l4 -4"></path>
                        <path d="M14 4l-10 10"></path>
                        <path d="M4 20l16 -16"></path>
                        <path d="M20 10l-10 10"></path>
                        <path d="M20 16l-4 4"></path>
                    </svg>
                </span>
                <span className="title"><Translate value="home.low_cost_speech_to_text_api_text" /></span>
            </div>
            <div className="subtitle">
            <Translate value="home.subtitle_text" />
            </div>
            <div className="cards">
                <div className="card">
                    <div className="svg-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M4 5h7"></path>
                            <path d="M9 3v2c0 4.418 -2.239 8 -5 8"></path>
                            <path d="M5 9c0 2.144 2.952 3.908 6.7 4"></path>
                            <path d="M12 20l4 -9l4 9"></path>
                            <path d="M19.1 18h-6.2"></path>
                        </svg>
                    </div>
                    <div className="card-title"><Translate value="home.lang_100_text" /></div>
                    <div className="card-content">
                        <Translate value="home.support_text" />
                    </div>
                </div>

                <div className="card">
                    <div className="svg-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                        </svg>
                    </div>
                    <div className="card-title"><Translate value="home.accurate_timestamps_text" /></div>
                    <div className="card-content">
                    <Translate value="home.using_the_text" />
                    </div>
                </div>

                <div className="card">
                    <div className="svg-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path
                                d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3">
                            </path>
                            <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                            <path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                        </svg>

                    </div>
                    <div className="card-title"><Translate value="home.performance_text" /></div>
                    <div className="card-content">
                    <Translate value="home.get_accureate_text" />
                    </div>
                </div>
                <div className="card">
                    <div className="svg-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path
                                d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3">
                            </path>
                        </svg>
                    </div>
                    <div className="card-title"><Translate value="home.low_cost_text" /></div>
                    <div className="card-content">
                    <Translate value="home.choose_model_text" />
                    </div>
                </div>
            </div>
        </div>
        <div className="main-3">
            <div className="main3-cards">
                <div className="main3-card">
                    <div className="title-box">
                        <span className="svg-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round">
                                <path d="M15 8h.01"></path>
                                <path
                                    d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z">
                                </path>
                                <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"></path>
                                <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"></path>
                            </svg>
                        </span>
                        <span className="title"><Translate value="home.background_music_text" /></span>
                    </div>
                    <div className="content">
                    <Translate value="home.separate_text" />
                    </div>
                    <div className="info-list">
                        <div className="info-item">
                            <span className="icon-box yellow">
                                ✔
                            </span>
                            <span className="info-text">
                            <Translate value="home.high_qulity_text" />
                            </span>
                        </div>
                        <div className="info-item">
                            <span className="icon-box yellow">
                                ✔
                            </span>
                            <span className="info-text">
                            <Translate value="home.separate_text" />
                            </span>
                        </div>
                        <div className="info-item">
                            <span className="icon-box yellow">
                                ✔
                            </span>
                            <span className="info-text">
                            <Translate value="home.just_text" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="main4-box">
            <div className="main4-title"><Translate value="home.simple_text" /></div>
            <div className="main4">
                <div className="main4-card">
                    <div className="main4-card-title">
                    <Translate value="home.top_up_with" /> $<span className="large">5</span>:
                        
                    </div>
                    <div className="main4-card-content"><Translate value="home.pay_only_text" /></div>
                    <div className="main4-card-list">
                        <div className="main4-card-item">
                            ✔
                            <div className="list-content"><Translate value="home.hours_240_text" /></div>
                        </div>
                        <div className="main4-card-item">
                            ✔
                            <div className="list-content">
                            <Translate value="home.or_30_text" />
                            </div>
                        </div>
                        
                        <div className="main4-card-item">
                            ✔
                            <div className="list-content"><Translate value="home.or_13_text" /></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="start-btn2 pointer" size="large">
            <a href="https://ai.voilatech.co.jp/login" style={{textDecoration:'none',color: 'black'}}><Translate value="home.start_now_text" /> ></a>
        </div>
        <div className="btn-foot">
            
            <span className="link pointer" onClick={() => window.location.href='/company-information/index.html'}>
            <Translate value="home.specified_commercial_text" />
                <span style={{marginLeft: '0.5rem'}} className="svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                        height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-external-link">
                        <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                        <path d="M11 13l9 -9"></path>
                        <path d="M15 4h5v5"></path>
                    </svg></span>
            </span>
        </div>
      </div>
    </Style>
  );
}

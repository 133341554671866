const en = {
    LOADING: 'Loading...',
    SUB_TEXT: 'Subtitle Text',
    OPEN_VIDEO: 'Open Video',
    OPEN_SUB: 'Open Subtitle',
    EXPORT_ASS: 'Export ASS',
    EXPORT_SRT: 'Export SRT',
    EXPORT_VTT: 'Export VTT',
    EXPORT_VIDEO: 'Export Video',
    TRANSLATE: 'Translation',
    TRANSLATING: 'Translation...',
    TRANSLAT_SUCCESS: 'Translation success',
    DECODE_START: 'Start decoding audio',
    DECODE_SUCCESS: 'Decoding audio success',
    DECODE_ERROR: 'Decoding audio failed',
    VIDEO_EXT_ERR: 'Unable to open this video format',
    SUB_EXT_ERR: 'Unable to open this subtitle format',
    MOBILE_TIP: 'Please use computer access',
    HOTKEY_01: 'Space: Play / Pause',
    HOTKEY_02: 'Ctrl + Z: Undo',
    CLEAR: 'Clear',
    UNDO: 'Undo',
    DELETE: 'Delete Subtitle',
    MERGE: 'Merge Next',
    SPLIT: 'Split Subtitle',
    LOADING_FFMPEG: 'Loading dependency...',
    LOADING_FONT: 'Loading font...',
    LOADING_VIDEO: 'Loading video...',
    LOADING_SUB: 'Loading subtitle...',
    CLEAR_TIP: 'Confirm that all data is cleared',
    BURN_ERROR: 'Burn subtitles failed',
    BURN_START: 'Start burn subtitles',
    BURN_SUCCESS: 'Burn subtitles success',
    OPEN_VIDEO_ERROR: 'Please open the video first',

    home: {
        header1: '12 hours of FREE transcription on our large model for new users!',
        get_start_btn_text: 'Get started',
        low_cost_ai_text: 'LOW COST AI',
        easy_to_use_text: 'Easy-to-Use',
        transcription_ai_apis_text: 'Transcription & AI APIs',
        transcription_ai_api_desc_text: 'Use our APIs to seperate vocals and to create audio transcripts.',
        speech_to_text_api_text: "Speech-to-Text API",
        background_music_separation_api_text: "Background Music Separation API",
        low_cost_speech_to_text_api_text: "Low-Cost Speech-to-Text API",
        subtitle_text: "Transcribe audio files within seconds starting at $0.04 per hour of speech.",
        lang_100_text: "100+ Languages",
        support_text: "Supporting many languages with the option to translate in our API.",
        accurate_timestamps_text: "Accurate Timestamps",
        using_the_text: "Using the Stable-ts library to provide more accurate timestmaps compared to the original Whisper.",
        performance_text: "Performance",
        get_accureate_text: "Get accurate transcriptions from the Whipser models, the latest and most precise speech\nrecognition\nAI model, with minimal latency.",
        low_cost_text: "Low-Cost",
        choose_model_text: "Most affordable price available. Choose your model size or a fine-model based on your needs to\nfurther reduce costs.",
        background_music_text: "Background Music Separation API",
        separate_text: "Separate vocals, drums, bass, and other instruments from music tracks.",
        high_qulity_text: "High-quality images with the powerful Demucs model.",
        separate_text: "Separate into 4 tracks: vocals, drums, bass, and other instruments.",
        just_text: "Just $0.0001 per second of audio ($0.36 per hour).",
        simple_text: "Simple Affordable Pricing",
        top_up_with: "Top up with",
        pay_only_text: "Pay only for what you use:",
        hours_240_text: "240 hours speech to text with Tiny model",
        or_30_text: "or 30 hours speech to text with Large model",
        or_13_text: "or 13 hours background music separation",
        start_now_text:"Start Now",
        specified_commercial_text: "特定商取引法表記",
    },

    header: {
        doc_text: "Docs",
        account_text: "Account",
        guides_text: "Guides"
    }
};

const jp = {
    home: {
        header1: '新規ユーザー向けの大規模モデルでの無料転写（12時間分）！',
        get_start_btn_text: '今すぐ始める',
        low_cost_ai_text: '低コストAI',
        easy_to_use_text: '使いやすい',
        transcription_ai_apis_text: '転写 & AI API',
        transcription_ai_api_desc_text: '我々のAPIを使用してボーカルを分離し、音声の転写を作成します。',
        speech_to_text_api_text: "音声からテキストへのAPI",
        background_music_separation_api_text: "バックグラウンド音楽分離API",
        low_cost_speech_to_text_api_text: "低コスト音声テキストAPI",
        subtitle_text: "1時間あたり$0.04から、数秒で音声ファイルを転写します。",
        lang_100_text: "100以上の言語",
        support_text: "多くの言語をサポートし、私たちのAPIで翻訳オプションがあります。",
        accurate_timestamps_text: "正確なタイムスタンプ",
        using_the_text: "Stable-tsライブラリを使用して、元のWhisperよりも正確なタイムスタンプを提供します。",
        performance_text: "パフォーマンス",
        get_accureate_text: "最新かつ最も正確な音声認識AIモデルであるWhipserモデルから正確な書き起こしを取得し、最小限のレイテンシを実現します。",
        low_cost_text: "低コスト",
        choose_model_text: "最も手頃な価格で利用可能。必要に応じてモデルサイズやファインチューニングモデルを選択して、コストをさらに削減できます。",
        background_music_text: "バックグラウンド音楽分離API",
        separate_text: "ボーカル、ドラム、ベース、その他の楽器を音楽トラックから分離します。",
        high_qulity_text: "強力なDemucsモデルを使用した高品質の画像。",
        separate_text: "ボーカル、ドラム、ベース、その他の楽器の4つのトラックに分離します。",
        just_text: "音声1秒あたり$0.0001（$0.36/時間）で提供します。",
        simple_text: "シンプルで手頃な料金",
        top_up_with: "にチャージ：",
        pay_only_text: "使用した分だけお支払いください：",
        hours_240_text: "Tinyモデルで240時間の音声からテキストへの変換",
        or_30_text: "もしくはLargeモデルで30時間の音声からテキストへの変換",
        or_13_text: "もしくは13時間のバックグラウンド音楽分離",
        start_now_text:"今すぐ始める",
        specified_commercial_text: "特定商取引法表記",
    },
    header: {
        doc_text: "ドキュメント",
        account_text: "アカウント",
        guides_text: "ガイド<"
    }
}

const it = {
    LOADING: "Caricamento in corso...",
    SUB_TEXT: "Sottotitoli",
    OPEN_VIDEO: "Apri video",
    OPEN_SUB: "Apri sottotitoli",
    EXPORT_ASS: "Esporta ASS",
    EXPORT_SRT: "Esporta SRT",
    EXPORT_VTT: "Esporta VTT",
    EXPORT_VIDEO: "Esporta video",
    TRANSLATE: 'Traduci',
    TRANSLATING: 'Traduzione...',
    TRANSLAT_SUCCESS: "Traduzione riuscita",
    DECODE_START: "Avvia decodifica audio",
    DECODE_SUCCESS: "Decodifica audio riuscita",
    DECODE_ERROR: "Decodifica audio non riuscita",
    VIDEO_EXT_ERR: "Impossibile aprire questo formato video",
    SUB_EXT_ERR: "Impossibile aprire questo formato di sottotitoli",
    MOBILE_TIP: "Utilizza l'accesso al computer",
    HOTKEY_01: "Spazio: Riproduci / Pausa",
    HOTKEY_02: "Ctrl + Z: Annulla",
    CLEAR: "Pulisci",
    UNDO: "Annulla",
    DELETE: "Elimina sottotitoli",
    MERGE: "Unisci dopo",
    SPLIT: "Dividi sottotitoli",
    LOADING_FFMPEG: "Caricamento dipendenza in corso...",
    LOADING_FONT: "Caricamento font in corso...",
    LOADING_VIDEO: "Caricamento video in corso...",
    LOADING_SUB: "Caricamento sottotitoli in corso...",
    CLEAR_TIP: "Conferma che tutti i dati siano stati cancellati",
    BURN_ERROR: "Masterizzazione sottotitoli non riuscita",
    BURN_START: "Avvia masterizzazione sottotitoli",
    BURN_SUCCESS: "Masterizzazione sottotitoli riuscita",
    OPEN_VIDEO_ERROR: "Apri prima il video",
};

const tr = {
    LOADING: 'Loading...',
    SUB_TEXT: 'Subtitle Text',
    OPEN_VIDEO: 'Video Aç',
    OPEN_SUB: 'Altyazı Aç',
    EXPORT_ASS: 'ASS Çıkar',
    EXPORT_SRT: 'SRT Çıkar',
    EXPORT_VTT: 'VTT Çıkar',
    EXPORT_VIDEO: 'Videoyu Çıkar',
    TRANSLATE: 'Çevir',
    TRANSLATING: 'Çevriliyor...',
    TRANSLAT_SUCCESS: 'Çevirme başarılı',
    DECODE_START: 'Ses çözümlemesi başladı',
    DECODE_SUCCESS: 'Ses çözümlemesi başarılı',
    DECODE_ERROR: 'Ses çözümlemesi başarısız',
    VIDEO_EXT_ERR: 'Bu video biçimi açılamıyor',
    SUB_EXT_ERR: 'Bu altyazı biçimi açılamıyor',
    MOBILE_TIP: 'Lütfen bilgisayardan erişin',
    HOTKEY_01: 'Boşluk: Oynat / Duraklat',
    HOTKEY_02: 'Ctrl + Z: Geri Al',
    CLEAR: 'Temizle',
    UNDO: 'Geri Al',
    DELETE: 'Altyazıyı Sil',
    MERGE: 'Sonrakiyle Birleştir',
    SPLIT: 'Altyazıyı Böl',
    LOADING_FFMPEG: 'Bağımlılık yükleniyor...',
    CLEAR_TIP: 'Tüm verilerin silineceğini onaylıyor musunuz?',
    BURN_ERROR: 'Altyazı gömme başarısız',
    BURN_START: 'Altyazı gömmeyi başlat',
    BURN_SUCCESS: 'Altyazı gömme başarılı',
    OPEN_VIDEO_ERROR: 'Lütfen önce videoyu açın',
};

const zh = {
    LOADING: '加载中...',
    SUB_TEXT: '字幕文本',
    OPEN_VIDEO: '打开视频',
    OPEN_SUB: '打开字幕',
    EXPORT_ASS: '导出 ASS',
    EXPORT_SRT: '导出 SRT',
    EXPORT_VTT: '导出 VTT',
    EXPORT_VIDEO: '导出视频',
    TRANSLATE: '批量翻译',
    TRANSLATING: '翻译中...',
    TRANSLAT_SUCCESS: '翻译成功',
    DECODE_START: '开始解码音频',
    DECODE_SUCCESS: '解码音频成功',
    DECODE_ERROR: '解码音频失败',
    VIDEO_EXT_ERR: '无法打开该视频格式',
    SUB_EXT_ERR: '无法打开该字幕格式',
    MOBILE_TIP: '请使用电脑访问',
    HOTKEY_01: '空格键: 播放 / 暂停',
    HOTKEY_02: 'Ctrl + Z: 撤销',
    CLEAR: '清空',
    UNDO: '撤销',
    DELETE: '删除字幕',
    MERGE: '合并下一个',
    SPLIT: '拆分字幕',
    LOADING_FFMPEG: '加载依赖中...',
    LOADING_FONT: '加载字体中...',
    LOADING_VIDEO: '加载视频中...',
    LOADING_SUB: '加载字幕中...',
    CLEAR_TIP: '确认清空所有数据吗',
    BURN_ERROR: '合成字幕失败',
    BURN_START: '开始合成字幕',
    BURN_SUCCESS: '合成字幕成功',
    OPEN_VIDEO_ERROR: '请先打开视频',
};

const i18n = {
    en,
    jp,
    it,
    zh,
    'zh-cn': zh,
    'zh-tw': zh,
    tr,
};

export default i18n;

import request from "../utils/request";

export const serveVideoApi = (url) => {
  return request({
    baseURL: '',
    url: url,
    method: "get",
  });
};
export const serveEditApi = (idx, newText, data) => {
  return request({
    baseURL: 'https://bideogo.azurewebsites.net',
    url: `/api/edit?index=${idx}&text=${newText}`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
export const serveWhisiperApi = (data) => {
  return request({
    baseURL: 'https://api.lemonfox.ai',
    url: `/v1/audio/transcriptions`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      // "Authorization": "zAynpH02NsMdjl4oq8Hy8KXWpdBpJ2SG", //注意安全，严禁外传
    },
    data,
  });
};
export const serveTranscribeApi = (data) => {
  return request({
    baseURL: 'https://bideogo.azurewebsites.net',
    url: `/api/transcribe`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
export const serveResplitApi = (data) => {
  return request({
    baseURL: 'https://bideogo.azurewebsites.net',
    url: `/api/resplit`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};
export const serveGetTrApi = (data) => {
  return request({
    baseURL: 'https://bideogo.azurewebsites.net',
    url: `/api/get_tr?tgt_lang=zh-cn`,
    method: "post",
    data,
  });
};
// 提取音频接口
export const extractAudioApi = (data) => {
  return request({
    baseURL: 'https://ffmpeg-api.calmpebble-34b8f3dc.eastus.azurecontainerapps.io',
    url: `/extract_audio`,
    responseType: 'blob',
    method: "post",
    data,
  });
};
// 合并视频和字幕接口
export const exportVideoApi = (data) => {
  return request({
    baseURL: 'https://ffmpeg-api.calmpebble-34b8f3dc.eastus.azurecontainerapps.io',
    url: `/export_video`,
    method: "post",
    responseType: 'blob',
    data,
  });
};
// 获取视频首帧 
export const firstFrameApi = (data) => {
  return request({
    baseURL: 'https://ffmpeg-api.calmpebble-34b8f3dc.eastus.azurecontainerapps.io',
    url: '/first_frame?name='+data.name,
    method: "get",
    responseType: 'blob',
    // params: {name: data.name}
  });
};
export const servePollingApi = (url) => {
  return request({
    baseURL: '',
    url: url,
    method: "get",
  });
};
export const serveSubsApi = (url, data) => {
  return request({
    baseURL: ``,
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};


import axios from "axios";
import cookie from "react-cookies";
import { errInfoApi, refreshTokenApi } from "../api";

import { notification } from "antd";


// const token = localStorage.getItem('sub-token') || ''; // 或者从其他地方获取token

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API, // 从.env文件中读取baseURL
  timeout: 999999999
});

const whiteList = [
  "/api/web/login",
  "/api/web/send_code",
  "/api/admin/refresh_token",
];

const serveApiList = [
  // "/api/edit",
  // "/v1/audio/transcriptions",
  // "/api/resplit",
  // "/api/get_tr",
  // "/api/transcribe"
  // "/runtime/webhooks/durabletask",
]

const getToken = () => {
  const token = cookie.load("token");
  const expires_time = cookie.load("expires_time");
  let expired = expires_time * 1000 <= Date.now();
  if (expired || !token || token === "undefined") {
    return "";
  } else {
    return token;
  }
};
const setToken = (res) => {
  let { token, refresh_token, expires_time } = res.data;
  cookie.save("token", token);
  cookie.save("expires_time", expires_time);
  if (refresh_token) cookie.save("refresh_token", refresh_token);
};

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requestList = [];

service.interceptors.request.use(
  (config) => {
    if (whiteList.includes(config.url)) {
      return config;
    }
    // const zuplo_key = cookie.load("zuplo_key");
    // if(serveApiList.includes(config.url)) {
    //   config.headers.Authorization = `Bearer ${zuplo_key}`;
    //   return config
    // }
    
    const token = getToken();

    if (token && token !== "undefined" && token !== "") {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      const refresh_token = cookie.load("refresh_token");
      if (!refresh_token) {
        if (window.location.pathname !== "/login")
          notification.error({ message: "登录已失效，请重新登录", duration: 2,onClose: () => {
            window.location.replace('/login');
          } });
        else { 
          notification.error({ message: "请重新登陆" });
        }
      } else {
        if (!isRefreshing) {
          isRefreshing = true;
          let promisefresh = new Promise(function (resolve, reject) {
            //刷新token
            refreshTokenApi(refresh_token).then((res) => {
              if (res.code === 0) {
                setToken(res);
                config.headers.Authorization = `Bearer ${res.token}`;
                // token 刷新后将数组里的请求队列方法重新执行
                if (requestList.length) {
                  requestList.forEach((cb) => cb(res.token));
                  requestList = [];
                  isRefreshing = false;
                }
                resolve(config);
              }
            });
          });
          return promisefresh;
        } else {
          // 后面的请求走这里排队
          // 返回未执行 resolve 的 Promise
          return new Promise((resolve) => {
            // 用函数形式将 resolve 存入，等待获取新token后再执行
            requestList.push((newToken) => {
              config.headers.Authorization = `Bearer ${newToken}`;
              resolve(config);
            });
          });
        }
      }
      // if(window.location.pathname !== '/login') {
      //   // 没有token，跳转到登录页面
      //   window.location.replace = '/login';
      // }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const res = response.data;
    if(res.code === 401) {
      if(window.location.pathname !== '/login') {
        // 令牌失效，跳转到登录页面
        notification.error({ message: "登录失效，请重新登录", duration: 2,onClose: () => {
          window.location.replace('/login');
        } });
      }
    }
    let {url} = response.config
    
    if(url == '/extract_audio' || url == '/export_video' || url == '/first_frame') {

      let {headers} = response
      
      if(url == '/extract_audio') {
        console.log('first-frame', headers['first-frame'])
        return {
          data: res,
          fileName: `${Date.now()}.mp3`,
          frameName: headers['first-frame']
        }
      } else {
        return {
          data: res,
          fileName: `${Date.now()}.mp3`
        }
      }
    }
    return res;
  },
  function (error) {
    console.log('requestErr',error);
    let {baseURL} = error.config
    if(baseURL === 'https://ytdlp-voilatech-apim.azure-api.net') {
      let errInfo = JSON.stringify(error.response)
      let api = error.config.url
      let query = error.config.params
      errInfoApi({})
      errInfoApi(api,query,errInfo)
    }
    return Promise.reject(error);
  }
);

export default service;
